<template>
  <div>
    <v-card :height="windowSize.y" v-if="!sentMessage">
      <v-card-title style="justify-content: center">
        <h3 class="headline mb-0">Contact Us</h3>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            style="
              color: black;
              font-family: Tahoma, Geneva, sans-serif;
              font-size: 16px;
            "
          >
            <v-btn
              href="tel:+16054411685"
              color="primary"
              class="white--text"
              dark
            >
              <v-icon left>mdi-phone</v-icon>
              (605) 441-1685
            </v-btn>
            Tomilyn's Cell Best Method of Contact - you can call or text
          </v-col>
        </v-row>
        <v-row>
          <v-col
            style="
              color: black;
              font-family: Tahoma, Geneva, sans-serif;
              font-size: 16px;
            "
          >
            <v-btn
              href="tel:+16057982116"
              color="primary"
              class="white--text"
              dark
            >
              <v-icon left>mdi-phone</v-icon>
              605-798-2116
            </v-btn>
            House
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              href="mailto:huntdakotaTrophy@gmail.com"
              color="primary"
              class="white--text"
              dark
            >
              <v-icon left>mdi-email</v-icon>
              huntdakotaTrophy@gmail.com Email Us
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            style="
              color: black;
              font-family: Tahoma, Geneva, sans-serif;
              font-size: 16px;
            "
          >
            <p>21858 Mule Deer Drive</p>
            <p>Wasta, SD 57791</p>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <form
              id="fs-frm"
              name="simple-contact-form"
              accept-charset="utf-8"
              action="https://formspree.io/f/meqnkogd"
              method="post"
            >
              <fieldset id="fs-frm-inputs">
                <label for="full-name">Full Name</label>
                <input
                  type="text"
                  name="name"
                  id="full-name"
                  placeholder="First and Last"
                  required=""
                />
                <label for="email-address">Email Address</label>
                <input
                  type="email"
                  name="_replyto"
                  id="email-address"
                  placeholder="email@domain.tld"
                  required=""
                />
                <label for="message">Message</label>
                <textarea
                  rows="5"
                  name="message"
                  id="message"
                  placeholder="Message"
                  required=""
                ></textarea>
                <input
                  type="hidden"
                  name="_subject"
                  id="email-subject"
                  value="Contact Form Submission"
                />
              </fieldset>
              <input type="submit" value="Submit" /></form></v-col
        ></v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Contact",

  components: {},
  methods: {
    getdata() {},
    validate() {},
  },
  data: function () {
    return {
      sentMessage: false,
      valid: true,
      name: "",
      nameRules: [
        (v) => !!v || "Name is required",
        (v) =>
          (v && v.length <= 100) || "Name must be less than 100 characters",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      description: null,
    };
  },
  mounted: function () {
    this.getdata();
  },
  computed: {
    windowSize: function () {
      return { x: window.innerWidth, y: window.innerHeight };
    },
  },
};
</script>

<style>
#fs-frm input,
#fs-frm select,
#fs-frm textarea,
#fs-frm fieldset,
#fs-frm optgroup,
#fs-frm label,
#fs-frm #card-element:disabled {
  font-family: inherit;
  font-size: 100%;
  color: inherit;
  border: none;
  border-radius: 0;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}
#fs-frm label,
#fs-frm legend,
#fs-frm ::placeholder {
  font-size: 0.825rem;
  margin-bottom: 0.5rem;
  padding-top: 0.2rem;
  display: flex;
  align-items: baseline;
}

/* border, padding, margin, width */
#fs-frm input,
#fs-frm select,
#fs-frm textarea,
#fs-frm #card-element {
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.9);
  padding: 0.75em 1rem;
  margin-bottom: 1.5rem;
}
#fs-frm input:focus,
#fs-frm select:focus,
#fs-frm textarea:focus {
  background-color: white;
  outline-style: solid;
  outline-width: thin;
  outline-color: gray;
  outline-offset: -1px;
}
#fs-frm [type="text"],
#fs-frm [type="email"] {
  width: 100%;
}
#fs-frm [type="button"],
#fs-frm [type="submit"],
#fs-frm [type="reset"] {
  width: auto;
  cursor: pointer;
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
}
#fs-frm [type="button"]:focus,
#fs-frm [type="submit"]:focus,
#fs-frm [type="reset"]:focus {
  outline: none;
}
#fs-frm [type="submit"],
#fs-frm [type="reset"] {
  margin-bottom: 0;
}
#fs-frm select {
  text-transform: none;
}

#fs-frm [type="checkbox"] {
  -webkit-appearance: checkbox;
  -moz-appearance: checkbox;
  appearance: checkbox;
  display: inline-block;
  width: auto;
  margin: 0 0.5em 0 0 !important;
}

#fs-frm [type="radio"] {
  -webkit-appearance: radio;
  -moz-appearance: radio;
  appearance: radio;
}

/* address, locale */
#fs-frm fieldset.locale input[name="city"],
#fs-frm fieldset.locale select[name="state"],
#fs-frm fieldset.locale input[name="postal-code"] {
  display: inline;
}
#fs-frm fieldset.locale input[name="city"] {
  width: 52%;
}
#fs-frm fieldset.locale select[name="state"],
#fs-frm fieldset.locale input[name="postal-code"] {
  width: 20%;
}
#fs-frm fieldset.locale input[name="city"],
#fs-frm fieldset.locale select[name="state"] {
  margin-right: 3%;
}
</style>
